<template>
  <div class="CalendarPage">
    <CreekCalendar />
  </div>
</template>

<script type="text/javascript">

import Calendar from '@/lib/creek/components/Calendar';

export default {
  name: 'CalendarPage',
  components: {
    CreekShows,
  },
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  created () {

  },
}

</script>
